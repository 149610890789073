<template>
  <tr v-if="link.examResults.length > 0" class="grey lighten-2">
    <td></td>
    <td class="text-center">Czy rozpoczęty</td>
    <td class="text-center">Czy ukończony</td>
    <td class="text-center">Czy sprawdzone</td>
    <td class="text-center">Czy zaliczone</td>
    <td class="text-center">Wynik</td>
    <td>Czy może być rozpoczęty</td>
    <td class="text-center">Daty</td>
    <td class="text-center">Link</td>
  </tr>
</template>
<script>
export default {
  name: 'OnlineExamDetailsTableHeader',
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
};
</script>
