





















import moment from 'moment';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { RunExamForLinkDocument } from '@/generated/operations';
import DateTimeField from '@/components/FormFields/DateTimeField.vue';
import ParticipantSelectField from '@/components/FormFields/ParticipantSelectField.vue';

export default {
  name: 'RunExamForm',
  components: { AbstractModalForm },
  props: {
    examName: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
    withDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    examId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      MUTATION: RunExamForLinkDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: ParticipantSelectField,
                model: { out: 'linkIds' },
                props: { label: 'Uczestnicy', courseId: this.courseId },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: DateTimeField,
                skip: () => !this.withDate,
                model: { out: 'beginDate' },
                initialData: () => moment().minutes(0).toISOString(),
                props: { label: 'Data rozpoczęcia' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: DateTimeField,
                skip: () => !this.withDate,
                model: { out: 'endDate' },
                initialData: () =>
                  moment()
                    .day(+7)
                    .hours(20)
                    .minutes(0)
                    .toISOString(),
                props: { label: 'Data zakończenia' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
