<template>
  <v-chip small v-if="unit">{{ unit.unitName }}</v-chip>
</template>
<script>
export default {
  name: 'UnitChip',
  props: {
    unit: {
      required: false,
      type: Object,
    },
  },
};
</script>
