






















import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { LinkParticipantToCourseDocument } from '@/generated/operations';
import UserSelectField from '@/components/FormFields/UserSelectField.vue';

export default {
  name: 'LinkParticipantToCourseForm',
  components: { AbstractModalForm },
  props: {
    congressCourseId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      MUTATION: LinkParticipantToCourseDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: UserSelectField,
                model: { out: 'userId' },
                props: { label: 'userId' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
