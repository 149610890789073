





























import AclScope from '@/components/Common/Security/AclScope.vue';

export default {
  name: 'CurrentUserExams',
  components: { AclScope },
  methods: {
    filterNonFinished(attempts) {
      return attempts.filter((attempt) => {
        return attempt.__typename === 'OnlineExamAttempt' && (!attempt.finished || attempt.canBeStarted);
      });
    },
  },
};
