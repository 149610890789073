
















import { GetOneCongressDateDetailsDocument, ListLevelsQueryDocument } from '@/generated/operations';

export default {
  name: 'LevelSelectField',
  props: {
    activeOnly: {
      type: Boolean,
      default: () => false,
    },
    value: String,
    label: String,
    congressDateId: { type: String, required: false },
    rules: { type: Array },
    formValues: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    entitiesComputed() {
      if (!this.entities) {
        return [];
      }

      const levelsIndex = this.congressDate.congressCourses.map((course) => course.level.id);
      return this.entities.filter((level) => !levelsIndex.includes(level.id));
    },
    internalValue: {
      get() {
        return String(this.value);
      },
      set(newValue) {
        this.$emit('input', String(newValue));
      },
    },
  },
  apollo: {
    entities: {
      query: ListLevelsQueryDocument,
      update(data) {
        return data.list.items;
      },
    },
    congressDate: {
      query: GetOneCongressDateDetailsDocument,
      variables() {
        return {
          id: this.congressDateId,
        };
      },
      update: (data) => data.congressDate.items[0],
    },
  },
};
