<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <GraphqlServerSideDataGrid
          title="Użytkownicy"
          :headers="headers"
          :query="query"
          :update="(data) => data.users.list"
          :addEnabled="false"
          @editItem="editItem"
          @displayItem="displayItem"
          enable-search
        >
          <template v-slot:[`title-buttons-left`]>
            <AclScope v-slot="{ currentUser }">
              <AddUserForm v-if="currentUser.acl_create_users" />
            </AclScope>
          </template>
          <template v-slot:[`item.rank`]="{ item }">
            <span v-if="item.rank">{{ item.rank.name }}</span>
          </template>
          <template v-slot:[`item.unit`]="{ item }">
            <UnitChip :unit="item.unit" />
          </template>
          <template v-slot:[`header.unit`]="{ header, applyFilter }">
            <FilterByUnit @applyFilter="applyFilter" :header="header" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="$router.push({ name: 'users/display', params: { id: item.id } })"
              x-small
              outlined
              class="mr-2"
            >
              pokaż
            </v-btn>
            <EditUserForm v-if="item.acl_edit" :user-id="item.id" class="mr-1" />
            <SendUserPasswordButton v-if="item.acl_send_password" :user="item" />
          </template>
        </GraphqlServerSideDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import USERS_LIST_QUERY from '@/graphql/user/query/ListUsersQuery.gql';
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import UnitChip from '@/components/Cwko/UnitChip.vue';
import FilterByUnit from '@/pages/user/UserListPage/FilterByUnit.vue';
import AddUserForm from '@/pages/user/UserListPage/AddUserForm.vue';
import AclScope from '@/components/Common/Security/AclScope.vue';
import EditUserForm from '@/pages/user/UserListPage/EditUserForm.vue';
import SendUserPasswordButton from '@/pages/security/Form/SendUserPasswordButton.vue';

export default {
  name: 'UsersListPage',
  components: {
    SendUserPasswordButton,
    EditUserForm,
    AclScope,
    AddUserForm,
    FilterByUnit,
    UnitChip,
    GraphqlServerSideDataGrid,
  },
  data() {
    return {
      query: USERS_LIST_QUERY,
      headers: [
        // { text: 'ID', sortable: true, value: 'id' },
        { text: 'Email', sortable: true, value: 'email' },
        { text: 'Imię', sortable: true, value: 'firstName' },
        { text: 'Nazwisko', sortable: true, value: 'lastName' },
        { text: 'Aktywny', sortable: true, value: 'active' },
        { text: 'Oddział', sortable: true, value: 'unit' },
        { text: 'Stopień', sortable: false, value: 'rank' },
        { text: 'Akcje', sortable: false, value: 'action' },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: 'users/edit', params: { id: item.id } });
    },
    displayItem(item) {
      this.$router.push({ name: 'users/display', params: { id: item.id } });
    },
  },
};
</script>
