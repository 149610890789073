<template>
  <div class="page a4">
    <img src="/assets/logo.png" class="logo center" />
    <div class="center-text">{{ link.participant.firstName }} {{ link.participant.lastName }}</div>
    <h2>Praktyczne</h2>
    <div class="div-table">
      <div v-for="result in practicalExams" :key="result.id" class="div-cell">
        <div class="label">{{ result.examVersion.exam.name }}</div>
        <div class="result">{{ result.resultInPercent }}%</div>
      </div>
    </div>
    <h2>Teoretyczne</h2>
    <div class="div-table">
      <div v-for="result in theoreticalExams" :key="result.id" class="div-cell">
        <div class="label">{{ result.examVersion.exam.name }}</div>
        <DiplomaResultWidget :result="result"/>
      </div>
    </div>
    <div v-if="link.rankRecommendation">
      {{ link.rankRecommendation }}
    </div>
    <div>{{ link.result.percents }}%</div>
  </div>
</template>
<script>
import DiplomaResultWidget from "@/pages/congressDate/DiplomaResultWidget.vue";

export default {
  name: 'DiplomaPage',
  components: {DiplomaResultWidget},
  computed: {
    practicalExams() {
      return this.link.examResults.filter((examResult) => examResult.examVersion.__typename === 'PracticalExamVersion');
    },
    theoreticalExams() {
      return this.link.examResults.filter(
        (examResult) =>
          examResult.examVersion.__typename === 'TheoreticalExamVersion' ||
          examResult.examVersion.__typename === 'OnlineExamVersion',
      );
    },
  },
  props: {
    link: { type: Object, required: true },
  },
};
</script>
<style>
.div-table {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}
.div-table > div {
  border: 1px solid;
  border-collapse: collapse;
}
.div-table > div > div {
  padding: 1mm;
  width: 50%;
  float: left;
}
.div-table .label {
  background: #ebebeb;
  height: 100%;
}

.logo {
  display: block;
  height: 2cm;
  margin-top: 0cm;
}

.center-text {
  text-align: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 5mm;
}

.page.a4 {
  width: 21cm;
  height: 29.7cm;
}

@media print {
  body,
  .page {
    margin: 0;
    box-shadow: 0;
  }
}
</style>
