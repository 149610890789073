<template>
  <v-expansion-panels v-if="!course.legacy && course.level.activeExams">
    <v-expansion-panel>
      <v-expansion-panel-header>Egzaminy na kursie</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nazwa</th>
                <th class="text-left">Typ</th>
                <th class="text-left">Akcje</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="rowClass(exam.activeVersion.__typename)"
                v-for="exam in course.level.activeExams"
                :key="exam.id"
              >
                <td>{{ exam.name }}</td>
                <td>{{ exam.activeVersion.__typename | mapTypes }}</td>
                <td v-if="exam.activeVersion.__typename === 'OnlineExamVersion'">
                  <RunExamForm class="mr-1" :exam-name="exam.name" :exam-id="exam.id" :course-id="course.id" withDate />
                  <OpenQuestionRateModal :exam-id="exam.id" :course-id="course.id" />
                </td>
                <td
                  v-else-if="
                    exam.activeVersion.__typename === 'PracticalExamVersion' ||
                    exam.activeVersion.__typename === 'TheoreticalExamVersion'
                  "
                >
                  <RunExamForm class="mr-1" :exam-name="exam.name" :exam-id="exam.id" :course-id="course.id" />
                  <ExamRateModal :exam-id="exam.id" :course-id="course.id" :label="`Ustaw wynik: ${exam.name}`" />
                </td>
                <td v-else>TODO for {{ exam.activeVersion.__typename }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import OpenQuestionRateModal from '@/pages/congressDate/widget/OpenQuestionRateModal.vue';
import RunExamForm from '@/pages/congressDate/form/RunExamForm.vue';
import ExamRateModal from '@/pages/congressDate/widget/ExamRateModal.vue';

export default {
  name: 'ExamsDetailsWidget',
  components: { ExamRateModal, OpenQuestionRateModal, RunExamForm },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  methods: {
    rowClass(type) {
      switch (type) {
        case 'OnlineExamVersion':
          return 'blue lighten-5';
        case 'PracticalExamVersion':
          return 'green lighten-5';
        case 'TheoreticalExamVersion':
          return 'yellow lighten-5';
        default:
          return '';
      }
    },
  },
  filters: {
    mapTypes(type) {
      switch (type) {
        case 'OnlineExamVersion':
          return 'Online';
        case 'PracticalExamVersion':
          return 'Praktyczny';
        case 'TheoreticalExamVersion':
          return 'Teoretyczny';
        default:
          return type;
      }
    },
  },
};
</script>
