<template>
  <span>
    <v-row>
      <v-col cols="6">
        <v-dialog ref="dateDialog" v-model="modalDate" persistent width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="disabled"
              v-model="date"
              :label="dateLabel"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalDate = false">Anuluj</v-btn>
            <v-btn text color="primary" @click="$refs.dateDialog.save(date)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <v-dialog
          v-if="showTime(formValues)"
          ref="timeDialog"
          v-model="modalTime"
          :return-value.sync="time"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :disabled="disabled"
              v-model="time"
              :label="timeLabel"
              prepend-icon="mdi-clock"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-time-picker format="24hr" v-if="modalTime" v-model="time" full-width>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalTime = false">Anuluj</v-btn>
            <v-btn text color="primary" @click="$refs.timeDialog.save(time)">OK</v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DateTimeField',
  data: () => ({
    modalDate: false,
    modalTime: false,
  }),
  props: {
    formValues: Object,
    dateLabel: String,
    value: String,
    timeLabel: String,
    isNew: Boolean,
    showTime: {
      type: Function,
      default: (formValues) => {
        return !formValues.fullDay;
      },
    },
    disabled: Boolean,
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof this.value === 'undefined') {
        this.$emit('input', moment().toISOString());
      }
    });
  },
  computed: {
    date: {
      set(value) {
        if (value === null) {
          this.$emit('input', null);
          return;
        }
        const oldValue = new Date(this.value);
        const newDate = moment(value);
        newDate.set({
          minute: oldValue.getMinutes(),
          hour: oldValue.getHours(),
        });
        this.$emit('input', newDate.format());
      },
      get() {
        if (typeof this.value !== 'undefined' && this.value !== null) {
          return moment(new Date(this.value)).format().substr(0, 10);
        }
        return null;
      },
      // get() {
      //   if (!this.value) {
      //     return null;
      //   }
      //   let date = new Date();
      //   if (typeof this.value !== 'undefined') {
      //     date = new Date(this.value);
      //   }
      //   return moment(date).format().substr(0, 10);
      // },
    },
    time: {
      set(value) {
        if (value === null) {
          this.$emit('input', null);
          return;
          // value = moment().format('hh:mm');
        }
        const oldValue = new Date(this.value);
        const newValue = new Date(`${oldValue.toISOString().substr(0, 10)} ${value}`);
        this.$emit('input', newValue.toISOString());
      },
      get() {
        if (!this.value) {
          return null;
        }
        let date = moment();
        if (typeof this.value !== 'undefined') {
          date = moment(this.value);
        }
        return date.format('HH:mm');
      },
    },
  },
};
</script>
