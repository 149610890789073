<template>
  <tr class="grey lighten-3">
    <td>{{ attempt.examVersion.exam.name }}</td>
    <td>
      <v-icon color="green" v-if="attempt.started">mdi-check</v-icon>
      <v-icon color="red" v-else>mdi-close-outline</v-icon>
    </td>
    <td>
      <v-icon color="green" v-if="attempt.finished">mdi-check</v-icon>
      <v-icon color="red" v-else>mdi-close-outline</v-icon>
    </td>
    <td>
      <v-icon color="green" v-if="!attempt.mustBeChecked">mdi-check</v-icon>
      <v-icon color="yellow darken-3" v-else>mdi-timer-sand</v-icon>
    </td>
    <td>
      <v-icon color="green" v-if="attempt.passed">mdi-check</v-icon>
      <v-icon color="red" v-else-if="attempt.finished">mdi-close-outline</v-icon>
      <v-icon color="yellow darken-3" v-else>mdi-timer-sand</v-icon>
    </td>
    <td>
      <strong>{{ attempt.resultInPercent }}% </strong>
      <br />
      <small>( próg {{ attempt.examVersion.pointThreshold }} punktów )</small>
    </td>
    <td>
      <v-icon color="green" v-if="attempt.canBeStarted">mdi-check</v-icon>
      <v-icon color="orange" v-else>mdi-close-outline</v-icon>
    </td>
    <td>
      Aktywne od <strong>{{ attempt.beginDate | dateTime }}</strong> do
      <strong>{{ attempt.endDate | dateTime }}</strong>
    </td>
    <td>
      Link:
      <v-btn x-small outlined @click="$router.push({ name: 'resolve-test', params: { token: attempt.token } })">
        Otwórz test
      </v-btn>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'OnlineExamDetailsTableRow',
  props: {
    attempt: {
      type: Object,
      required: true,
    },
    origin: {},
  },
};
</script>
