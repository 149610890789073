var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('GraphqlServerSideDataGrid',{attrs:{"title":"Użytkownicy","headers":_vm.headers,"query":_vm.query,"update":function (data) { return data.users.list; },"addEnabled":false,"enable-search":""},on:{"editItem":_vm.editItem,"displayItem":_vm.displayItem},scopedSlots:_vm._u([{key:"title-buttons-left",fn:function(){return [_c('AclScope',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentUser = ref.currentUser;
return [(currentUser.acl_create_users)?_c('AddUserForm'):_vm._e()]}}])})]},proxy:true},{key:"item.rank",fn:function(ref){
var item = ref.item;
return [(item.rank)?_c('span',[_vm._v(_vm._s(item.rank.name))]):_vm._e()]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('UnitChip',{attrs:{"unit":item.unit}})]}},{key:"header.unit",fn:function(ref){
var header = ref.header;
var applyFilter = ref.applyFilter;
return [_c('FilterByUnit',{attrs:{"header":header},on:{"applyFilter":applyFilter}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'users/display', params: { id: item.id } })}}},[_vm._v(" pokaż ")]),(item.acl_edit)?_c('EditUserForm',{staticClass:"mr-1",attrs:{"user-id":item.id}}):_vm._e(),(item.acl_send_password)?_c('SendUserPasswordButton',{attrs:{"user":item}}):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }