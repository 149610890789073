<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card v-if="congressDate">
          <v-app-bar dark color="primary">
            <v-toolbar-title>
              {{ congressDate.place }} {{ congressDate.beginDate | date }} - {{ congressDate.endDate | date }} ::
              {{ congressDate.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-card-actions>
            <AclScope v-slot="{ currentUser }">
              <AddCongressCourseForm
                :congress-date-id="congressDate.id"
                v-if="currentUser.acl_create_congress_course"
                class="mr-1"
              />
            </AclScope>
            <v-btn outlined x-small @click="$apollo.queries.congressDate.refetch()">odśwież</v-btn>
          </v-card-actions>
          <span v-for="course in congressDate.congressCourses" :key="course.id">
            <v-app-bar dark color="secondary">
              {{ course.level.name }} <v-chip small class="ml-2">{{ course.participantsLinks.length }}</v-chip>
            </v-app-bar>
            <v-card-actions>
              <LinkParticipantToCourseForm v-if="course.acl_edit" :congress-course-id="course.id" />
            </v-card-actions>
            <v-card-text>
              <ExamsDetailsWidget :course="course" />
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th colspan="9">Imię i nazwisko</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="link in course.participantsLinks">
                      <UserHeaderTableHeader :link="link" :key="link.id" />
                      <OnlineExamDetailsTableHeader :link="link" :key="link.id + '_2'" />
                      <OnlineExamDetailsTableRow
                        v-for="attempt in filterOnlineAttempts(link.examResults)"
                        :key="attempt.id"
                        :attempt="attempt"
                        :origin="origin"
                      />
                      <PracticalExamDetailsTableHeader :key="link.id + '_3'" />
                      <PracticalExamDetailsTableRow
                        v-for="attempt in filterPointedAttempts(link.examResults)"
                        :key="attempt.id"
                        :attempt="attempt"
                      />
                    </template>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </span>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QUERY from '@/graphql/congressDate/query/GetOneCongressDateQuery.gql';
import AddCongressCourseForm from '@/pages/congressDate/form/AddCongressCourseForm.vue';
import LinkParticipantToCourseForm from '@/pages/congressDate/form/LinkParticipantToCourseForm.vue';
import AclScope from '@/components/Common/Security/AclScope.vue';
import OnlineExamDetailsTableRow from '@/pages/congressDate/OnlineExamDetailsTableRow.vue';
import ExamsDetailsWidget from '@/pages/congressDate/ExamsDetailsWidget.vue';
import OnlineExamDetailsTableHeader from '@/pages/congressDate/OnlineExamDetailsTableHeader.vue';
import UserHeaderTableHeader from '@/pages/congressDate/UserHeaderTableHeader.vue';
import PracticalExamDetailsTableRow from '@/pages/congressDate/PracticalExamDetailsTableRow.vue';
import PracticalExamDetailsTableHeader from '@/pages/congressDate/PracticalExamDetailsTableHeader.vue';

export default {
  name: 'CongressDateDisplayPage',
  components: {
    PracticalExamDetailsTableHeader,
    PracticalExamDetailsTableRow,
    UserHeaderTableHeader,
    OnlineExamDetailsTableHeader,
    ExamsDetailsWidget,
    OnlineExamDetailsTableRow,
    AclScope,
    LinkParticipantToCourseForm,
    AddCongressCourseForm,
  },
  computed: {
    origin() {
      return window.location.origin;
    },
  },
  methods: {
    filterOnlineAttempts(values) {
      return values.filter((value) => {
        return value.__typename === 'OnlineExamAttempt';
      });
    },
    filterPointedAttempts(values) {
      return values.filter((value) => {
        return ['PracticalExamResult', 'TheoreticalExamResult'].includes(value.__typename);
      });
    },
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return { QUERY };
  },
  apollo: {
    congressDate: {
      query: QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
      update: (data) => data.congressDate.items[0],
    },
  },
};
</script>
<style>
.finished.passed {
  background-color: rgb(0, 170, 0);
}

.finished.not-passed {
  background-color: #ff4545;
}

.not-finished {
  background-color: gray;
}

.not-started {
  background-color: #bdbdff;
}
</style>
