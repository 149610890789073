<template>
  <span>
    <v-btn outlined x-small @click="openDialog">Oceń otwarte pytania</v-btn>
    <v-dialog :value="dialog" persistent :fullscreen="false" :scrollable="true" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Oceń odpowiedzi</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container style="overflow: auto">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Pytanie</th>
                  <th class="text-left">Plik</th>
                  <th class="text-left">Odpowiedź</th>
                  <th class="text-left">Punkty</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id" :class="!item.checked ? 'yellow lighten-5' : ''">
                  <td>{{ item.question.name }}</td>
                  <td><ShowFile max-height="100px" :file="item.question.file" /></td>
                  <td>
                    <VAlert color="orange" dense v-if="!item.examAttempt.finished">
                      <v-icon>mdi-alert-circle</v-icon> egzamin nie został jeszcze ukończony
                    </VAlert>
                    <div style="white-space: pre-line">{{ item.userAnswerContent }}</div>
                  </td>
                  <td>{{ item.userPoints }} / {{ item.question.maxPoints }}</td>
                  <td>
                    <OpenQuestionRateWidget
                      :value="item.userPoints"
                      :answer-id="item.id"
                      :max-value="item.question.maxPoints"
                      :item="item"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { ListOpenAnswersForCourseExamDocument } from '@/generated/operations';
import OpenQuestionRateWidget from '@/pages/congressDate/widget/OpenQuestionRateWidget.vue';
import ShowFile from '@/pages/exam/ShowFile.vue';

export default {
  name: 'OpenQuestionRateModal',
  components: { ShowFile, OpenQuestionRateWidget },
  props: {
    examId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
    submitLabel: {
      type: String,
      default: 'Zapisz',
    },
    maxWidth: {
      type: String,
      default: '800px',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
  apollo: {
    items: {
      query: ListOpenAnswersForCourseExamDocument,
      variables() {
        return {
          examId: this.examId,
          courseId: this.courseId,
        };
      },
      skip() {
        return !this.dialog;
      },
      update: (data) => data.list,
    },
  },
};
</script>
