<template>
  <span>
    <span v-if="result.examVersion.maxPoints == 1">
      {{ result.resultInPercent ? 'Zal.' : 'Nie zal.' }}
    </span>
    <span v-else>{{ result.resultInPercent }}%</span>
  </span>
</template>
<script>
export default {
  name: 'DiplomaResultWidget',
  props: {
    result: { type: Object },
  },
};
</script>
