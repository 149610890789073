<template>
  <v-container>
    <v-row v-if="user">
      <v-col md="4" sm="12">
        <v-card class="mx-auto" max-width="800">
          <v-toolbar color="secondary" dark>
            <v-toolbar-title>Dane podstawowe</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-actions>
            <EditUserForm v-if="user.acl_edit" :user-id="user.id" class="mr-1" />
          </v-card-actions>
          <v-card-title> {{ user.lastName }} {{ user.firstName }}</v-card-title>
          <v-card-subtitle v-if="user.rank"> {{ user.rank.short }} </v-card-subtitle>
          <v-card-subtitle> Oddział: {{ user.unit.unitName }} </v-card-subtitle>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
      <v-col md="8" sm="12">
        <v-card class="mx-auto" max-width="800">
          <v-toolbar color="secondary" dark>
            <v-toolbar-title>Kursy</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div v-for="course in user.congressCoursesList" :key="course.id">
            <v-card-title>{{ course.congressCourse.level.name }}</v-card-title>
            <v-card-text>
              <p class="mb-0"><strong>Zaliczony:</strong> {{ course.passed ? 'tak' : 'nie' }}</p>
              <p class="mb-0">
                <strong>Wynik:</strong>
                <span v-if="course.result.points > 0 && course.result.percents == 0">
                  {{ course.result.points }} pkt.
                </span>
                <span v-else> {{ course.result.percents | osrDash }} % </span>
              </p>
              <p class="mb-0">
                <strong>Data:</strong> {{ course.congressCourse.congressDate.beginDate | date }} -
                {{ course.congressCourse.congressDate.endDate | date }}
              </p>
              <p class="mb-0"><strong>Miejsce:</strong> {{ course.congressCourse.congressDate.place }}</p>
              <p class="mb-0"><strong>Numer zjazdu:</strong> {{ course.congressCourse.congressDate.number }}</p>
              <p class="mb-0"><strong>Opis zjazdu:</strong> {{ course.congressCourse.congressDate.name }}</p>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-skeleton-loader v-else type="table" />
  </v-container>
</template>

<script>
import USER_DETAILS from '@/graphql/user/query/UserDetailsQuery.gql';
import EditUserForm from '@/pages/user/UserListPage/EditUserForm.vue';

export default {
  name: 'UserDetailsPage',
  components: { EditUserForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {},
  apollo: {
    user: {
      query: USER_DETAILS,
      variables() {
        return {
          userId: this.id,
        };
      },
      update: (data) => data.user.items[0],
    },
  },
};
</script>
