<template>
  <tr>
    <td class="text-left">
      <strong>{{ link.participant.lastName }} {{ link.participant.firstName }}</strong>
    </td>
    <td>{{ link.result.passed ? 'zaliczone' : 'nie zaliczone' }}</td>
    <td>Lokata: {{ link.result.place | orDash }} <span v-if="link.result.place"></span></td>
    <td>{{ link.result.points | orDash }} pkt. / {{ link.result.maxPoints | orDash }} pkt.</td>
    <td>
      Rekomendacja:
      <span v-if="link.rankRecommendation">
        {{ link.rankRecommendation.name }}
      </span>
      <span v-else>brak</span>
    </td>
    <td colspan="6"></td>
    <td v-if="link.acl_delete">TODO delete</td>
  </tr>
</template>
<script>
export default {
  name: 'UserHeaderTableHeader',
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
};
</script>
