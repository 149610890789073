<template>
  <span>
    <AbstractModalForm
      v-if="formSpecification"
      label="Dodaj kurs"
      button-label="dodaj kurs"
      :refetch-queries="['GetOneCongressDateDetails']"
      :form-specification="formSpecification"
      :mutation="MUTATION"
      :mutation-error-mapper="(data) => (data.data.addCongressCourse.errors ? data.data.addCongressCourse.errors : [])"
      :mutation-variables="
        (data) => {
          data.congressDateId = congressDateId;
          return { input: data };
        }
      "
      @done="$emit('close')"
    />
  </span>
</template>

<script>
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { AddCongressCourseDocument } from '@/generated/operations';
import LevelSelectField from '@/components/FormFields/LevelSelectField.vue';

export default {
  name: 'AddCongressCourseForm',
  components: { AbstractModalForm },
  props: {
    congressDateId: {
      type: String,
      required: true,
    },
    refetchQueries: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      MUTATION: AddCongressCourseDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: LevelSelectField,
                model: { out: 'levelId' },
                props: {
                  label: 'Poziom kursu',
                  congressDateId: this.congressDateId,
                },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
</script>
