























import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { AddUserDocument } from '@/generated/operations';

export default {
  name: 'AddUserForm',
  components: { AbstractModalForm },
  methods: {
    handleDone({ data: { addUser } }) {
      this.$emit('userAdded', addUser);
    },
  },
  data() {
    return {
      showModal: false,
      MUTATION: AddUserDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'email' },
                props: { label: 'email' },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'firstName' },
                props: { label: 'Imię' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'lastName' },
                props: { label: 'Nazwisko' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: undefined,
                model: { out: 'unitId' },
                props: { label: 'unitId' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
  props: {
    refetchQueries: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
