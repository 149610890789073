<template>
  <tr class="blue lighten-3">
    <td>{{ attempt.examVersion.exam.name }}</td>
    <td>{{ attempt.examVersion.__typename | mapTypes }}</td>
    <td colspan="2"></td>
    <td>
      <v-icon color="green" v-if="attempt.passed === true">mdi-check</v-icon>
      <v-icon color="red" v-else-if="attempt.passed === false">mdi-close-outline</v-icon>
      <v-icon color="yellow darken-3" v-else>mdi-timer-sand</v-icon>
    </td>
    <td>
      <strong>{{ attempt.resultInPercent }}%</strong>
      <br />
      <small>
        ( próg: {{ attempt.examVersion.pointThreshold }} punktów ) (wynik: {{ attempt.points }} pkt.) (max:
        {{ attempt.examVersion.maxPoints }} pkt.)
      </small>
    </td>
    <td colspan="3">-</td>
  </tr>
</template>
<script>
export default {
  name: 'PracticalExamDetailsTableRow',
  props: {
    attempt: {
      type: Object,
      required: true,
    },
  },
  filters: {
    mapTypes(type) {
      switch (type) {
        case 'OnlineExamVersion':
          return 'Online';
        case 'PracticalExamVersion':
          return 'Praktyczny';
        case 'TheoreticalExamVersion':
          return 'Teoretyczny';
        default:
          return type;
      }
    },
  },
};
</script>
