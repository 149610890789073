var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('GraphqlServerSideDataGrid',{attrs:{"title":"Kursy","headers":_vm.headers,"query":_vm.query,"update":function (data) { return data.list.items; },"addEnabled":false,"sort-by":['beginDate']},on:{"editItem":_vm.editItem,"displayItem":_vm.displayItem},scopedSlots:_vm._u([{key:"title-buttons-left",fn:function(){return [_c('AclScope',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentUser = ref.currentUser;
return [(currentUser.acl_create_congress_date)?_c('AddCongressDateForm'):_vm._e()]}}])})]},proxy:true},{key:"item.beginDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.beginDate))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.number ? item.number : '-')+" ")]}},{key:"item.courses",fn:function(ref){
var item = ref.item;
return [_c('CongressCoursesListChip',{attrs:{"congressCourses":item.congressCourses}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }