























import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { EditUserDocument, UserDetailsDocument } from '@/generated/operations';
import UnitSelectField from '@/components/FormFields/UnitSelectField.vue';
import RankSelectField from '@/components/FormFields/RankSelectField.vue';

export default {
  name: 'EditUserForm',
  components: { AbstractModalForm },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      MUTATION: EditUserDocument,
      QUERY: UserDetailsDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'email' },
                props: { label: 'email' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'firstName' },
                props: { label: 'imię' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'lastName' },
                props: { label: 'nazwisko' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: UnitSelectField,
                model: { in: 'unit.id', out: 'unitId' },
                props: { label: 'oddział' },
                skip: ({ entity }) => {
                  return !entity.acl_edit_all;
                },
                rules: [],
              },
              {
                cols: { md: 12 },
                type: RankSelectField,
                model: { in: 'rank.id', out: 'rankId' },
                props: { label: 'stopień' },
                skip: ({ entity }) => {
                  return !entity.acl_edit_all;
                },
                rules: [],
              },
            ],
          },
        ],
      },
    };
  },
};
