





















import { GetOneCongressCourseDetailsDocument } from '@/generated/operations';

export default {
  name: 'ParticipantSelectField',
  props: {
    value: {
      type: [String, Array],
    },
    courseId: { type: String, required: true },
    label: String,
    rules: { type: Array },
    deletable: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    selectAll() {
      this.$emit(
        'input',
        this.links.map((link) => link.id),
      );
    },
    onUserAdded(data) {
      this.myInternalValue = data.id;
    },
  },
  computed: {
    links() {
      if (!this.course) {
        return [];
      }
      return this.course.participantsLinks.map((link) => {
        return {
          id: link.id,
          label: `${link.participant.lastName} ${link.participant.firstName}`,
        };
      });
    },
    isLoading() {
      return this.$apollo.queries.course.loading;
    },
    myInternalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  apollo: {
    course: {
      query: GetOneCongressCourseDetailsDocument,
      update(data) {
        return data.congressCourse.items[0];
      },
      variables() {
        return { id: this.courseId };
      },
    },
  },
};
