<template>
  <div>
    <div v-if="congressDate">
      <div v-for="course of congressDate.congressCourses" :key="course.id">
        <div v-for="link of course.participantsLinks" :key="link.id">
          <DiplomaPage :link="link" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QUERY from '@/graphql/congressDate/query/GetOneCongressDateQuery.gql';
import DiplomaPage from '@/pages/congressDate/DiplomaPage.vue';

export default {
  name: 'CongressDatePrintDiplomasPage',
  components: { DiplomaPage },
  methods: {
    filterOnlineAttempts(values) {
      return values.filter((value) => {
        return value.__typename === 'OnlineExamAttempt';
      });
    },
    filterPointedAttempts(values) {
      return values.filter((value) => {
        return ['PracticalExamResult', 'TheoreticalExamResult'].includes(value.__typename);
      });
    },
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return { QUERY };
  },
  apollo: {
    congressDate: {
      query: QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
      update: (data) => data.congressDate.items[0],
    },
  },
};
</script>
