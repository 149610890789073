<template>
  <div>
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small outlined color="primary" dark v-bind="attrs" v-on="on">
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-card class="pt-2 pb-2 pl-2 pr-2" style="width: 400px">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="internalValue"
              item-text="unitName"
              item-value="id"
              chips
              clearable
              small-chips
              :items="items"
            />
          </v-col>
          <v-col cols="4">
            <v-btn @click="propagate">
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import LIST_UNITS from '@/graphql/unit/query/ListUnitsQuery.gql';

export default {
  name: 'FilterByUnit',
  data() {
    return {
      internalValue: null,
    };
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  methods: {
    propagate() {
      // const temp = this.filters();
      // temp[this.header.value] = { value: this.internalValue, type: 'IN' };
      // this.applyFilter(temp);
      // this.applyFilter(this.header.value, this.internalValue[0], 'IN');
      this.$emit('applyFilter', { field: this.header.value, type: 'IN', value: this.internalValue });
    },
  },
  apollo: {
    items: {
      query: LIST_UNITS,
      update: (data) => data.list.items,
    },
  },
};
</script>
