<template>
  <v-dialog ref="dateDialog" v-model="modal" persistent width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
        :persistent-hint="persistentHint"
        :hint="hint"
        outlined
        :rules="rules"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false">Anuluj</v-btn>
      <v-btn text color="primary" @click="$refs.dateDialog.save(date)">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DateField',
  data: () => ({
    modal: false,
  }),
  props: {
    label: String,
    value: String,
    timeLabel: String,
    isNew: Boolean,
    disabled: Boolean,
    persistentHint: Boolean,
    hint: String,
    rules: { type: Array },
  },
  mounted() {
    const newDate = moment();
    this.$emit('input', newDate.format());
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    date: {
      set(value) {
        const newDate = moment(value);
        this.$emit('input', newDate.format());
      },
      get() {
        if (typeof this.value !== 'undefined') {
          return moment(new Date(this.value)).format().substr(0, 10);
        }
        return null;
      },
    },
  },
};
</script>
