<template>
  <span>
    <v-chip v-for="course in congressCourses" class="mr-1" :key="course.id">{{ course.level.name }}</v-chip>
  </span>
</template>
<script>
export default {
  name: 'CongressCoursesListChip',
  props: {
    congressCourses: {
      type: Array,
      required: true,
    },
  },
};
</script>
