<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          label="punkty"
          type="number"
          :max="maxValue"
          min="0"
          outlined
          dense
          :disabled="loading"
          :loading="loading"
          v-model="points"
          suffix="pkt."
          max-width="50px"
          append-outer-icon="mdi-plus"
          prepend-icon="mdi-minus"
          class="mt-1"
          @click:append-outer="points += 1"
          @click:prepend="points -= 1"
        />
      </v-col>
      <v-col>
        <v-btn class="mt-2" small color="primary" :loading="loading" @click="mutate">zapisz</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { rateOpenAnswerMutation } from '@/generated/operations';

export default {
  name: 'OpenQuestionRateWidget',
  data() {
    return {
      loading: false,
      points: 0,
    };
  },
  mounted() {
    this.points = this.value;
  },
  watch: {
    points(value) {
      if (value > this.maxValue) {
        this.points = this.maxValue;
      }
      if (value < 0) {
        this.points = 0;
      }
    },
  },
  props: {
    answerId: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async mutate() {
      this.loading = true;
      const { success, data, errors } = await rateOpenAnswerMutation(this, {
        variables: {
          answerId: this.answerId,
          points: parseInt(this.points, 10),
        },
      });
      console.log(success);
      console.log(data);
      console.log(errors);
      this.loading = false;
    },
  },
};
</script>
