<template>
  <span v-if="user">
    <ConfirmModal
      title="Wyślij hasło"
      :content="`Użytkownik dostanie na adres <strong>${user.email}</strong> dalsze instrukcje jak zresetować hasło. Czy kontynuować ?`"
      @confirm="send"
      :loading="loading"
    >
      <template v-slot:activator="{ openModal }">
        <v-btn x-small outlined @click="openModal()">wyślij hasło</v-btn>
      </template>
    </ConfirmModal>
  </span>
</template>

<script>
import ConfirmModal from '@/components/Common/ConfirmModal.vue';
import { ForgotPasswordDocument } from '@/generated/operations';

export default {
  name: 'SendUserPasswordButton',
  components: { ConfirmModal },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    send() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: ForgotPasswordDocument,
          variables: {
            email: this.user.email,
          },
        })
        .then(({ data: { user } }) => {
          if (user.errors && user.errors[0]) {
            // eslint-disable-next-line no-alert
            alert(user.errors[0].message);
          }
          this.loading = false;
        });
    },
  },
};
</script>
