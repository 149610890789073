




















import { VTextField } from 'vuetify/lib';
import moment from 'moment';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { AddCongressDateDocument } from '@/generated/operations';
import DateField from '@/components/FormFields/DateField.vue';

export default {
  name: 'AddCongressDateForm',
  components: { AbstractModalForm },
  data() {
    return {
      showModal: false,
      MUTATION: AddCongressDateDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: DateField,
                model: { out: 'beginDate' },
                props: { label: 'beginDate' },
                initialData: () => moment().hours(20).minutes(0).toISOString(),
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: DateField,
                model: { out: 'endDate' },
                props: { label: 'endDate' },
                initialData: () => moment().days(7).hours(20).minutes(0).toISOString(),
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'place' },
                props: { label: 'miejsce' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'number' },
                props: { label: 'numer zjazdu' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'nazwa / imię partona zjazdu' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
