<template>
  <span>
    <v-autocomplete
      outlined
      item-value="id"
      item-text="lastName"
      :disabled="isLoading"
      :items="users"
      :loading="isLoading"
      v-model="internalValue"
      v-bind="$props"
      prepend-icon="mdi-account-outline"
    >
      <template slot="selection" slot-scope="data">
        <v-chip class="mr-0 pr-0">
          {{ data.item.lastName }} {{ data.item.firstName }}
          <span v-if="data.item.userName">( {{ data.item.userName }} )</span>
          <v-chip class="ml-1" rounded v-if="deletable" @click="$emit('input', null)">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-chip>
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        {{ data.item.lastName }} {{ data.item.firstName }}
        <span v-if="data.item.userName">( {{ data.item.userName }} )</span>
      </template>
      <template slot="append-outer">
        <AddUserForm :refetch-queries="['FormListUsers']" @userAdded="onUserAdded">
          <template v-slot:activator="{ openDialog }">
            <v-btn @click="openDialog"><v-icon small left>mdi-plus</v-icon><v-icon>mdi-account</v-icon></v-btn>
          </template>
        </AddUserForm>
      </template>
    </v-autocomplete>
  </span>
</template>
<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';
import LIST_USERS from '@/graphql/user/query/ListUsersForFormQuery.gql';
import AddUserForm from '@/pages/user/UserListPage/AddUserForm.vue';

export default {
  name: 'UserSelectField',
  components: { AddUserForm },
  props: {
    value: String,
    label: String,
    rules: { type: Array },
    deletable: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    onUserAdded(data) {
      this.internalValue = data.id;
    },
  },
  computed: {
    isLoading() {
      return this.$apollo.queries.users.loading || this.$apollo.queries.currentUser.loading;
    },
    internalValue: {
      get() {
        // if (!this.value) {
        //   return this.currentUser.id;
        // }
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  apollo: {
    users: {
      query: LIST_USERS,
      update(data) {
        return data.listUsers.items;
      },
    },
    currentUser: {
      query: CURRENT_USER,
      update(data) {
        this.$emit('input', data.me.id);
        return data.me;
      },
    },
  },
};
</script>
