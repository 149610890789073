<template>
  <v-container style="min-width: 200px" class="mr-10">
    <v-row>
      <v-col>
        <v-text-field
          label="punkty"
          type="number"
          :max="maxValue"
          min="0"
          :step="0.25"
          outlined
          dense
          :disabled="loading"
          :loading="loading"
          v-model="points"
          max-width="200px"
          min-width="200px"
          append-outer-icon="mdi-plus"
          prepend-icon="mdi-minus"
          class="mt-1 mb-0"
          @click:append-outer="points += 1"
          @click:prepend="points -= 1"
          tabindex="0"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="pt-0">
        <v-checkbox dense v-model="wasResit" label="poprawka" />
      </v-col>
      <v-col class="pt-0">
        <v-btn class="mt-0" fab small color="primary" :loading="loading" @click="mutate">
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { gradePointExamResultMutation } from '@/generated/operations';

export default {
  name: 'ExamSetPointsForm',
  data() {
    return {
      loading: false,
      points: 0,
      wasResit: false,
    };
  },
  mounted() {
    this.points = this.value.points;
    this.wasResit = this.value.wasResit;
  },
  watch: {
    points(value) {
      if (value > this.maxValue) {
        this.points = this.maxValue;
      }
      if (value < 0) {
        this.points = 0;
      }
    },
  },
  props: {
    resultId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async mutate() {
      this.loading = true;
      const { success, data, errors } = await gradePointExamResultMutation(this, {
        variables: {
          resultId: this.resultId,
          points: parseFloat(this.points, 10),
          wasResit: this.wasResit,
        },
      });
      console.log(success);
      console.log(data);
      console.log(errors);
      this.loading = false;
    },
  },
};
</script>
