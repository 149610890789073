<template>
  <tr class="blue lighten-2">
    <td>Egzaminy praktyczne</td>
    <td colspan="3"></td>
    <td>Czy zaliczone</td>
    <td>Wynik</td>
    <td colspan="3"></td>
  </tr>
</template>
<script>
export default {
  name: 'PracticalExamDetailsTableHeader',
};
</script>
