<template>
  <span>
    <v-btn outlined x-small @click="openDialog">Ustaw wyniki</v-btn>
    <v-dialog :value="dialog" persistent :fullscreen="false" :scrollable="true" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ label }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container style="overflow: auto">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Kursant</th>
                  <th class="text-left">Oddział</th>
                  <th class="text-left">Punkty zdobyte / Max do zdobycia</th>
                  <th class="text-left">Wynik w procentach</th>
                  <th class="text-left">Próg zaliczenia</th>
                  <th class="text-left">Próg zaliczenia na wyższy stopień</th>
                  <th class="text-left">Zaliczone</th>
                  <th class="text-left">Zaliczone na wyższy stopień</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in items"
                  :key="`${item.id}_${item.points}`"
                  :class="item.points === 0 ? 'yellow lighten-5' : ''"
                >
                  <td>{{ item.participant.lastName }} {{ item.participant.firstName }}</td>
                  <td>
                    {{ item.participant.unit ? item.participant.unit.unitName : '-' }}
                  </td>
                  <td>{{ item.points }} pkt. / {{ item.examVersion.maxPoints }} pkt.</td>
                  <td>{{ item.resultInPercent }}%</td>
                  <td>{{ item.examVersion.pointThreshold }} punktów</td>
                  <td>
                    <span v-if="item.examVersion.pointThresholdHigherRank">
                      {{ item.examVersion.pointThresholdHigherRank }}%
                    </span>
                    <span v-else>brak</span>
                  </td>
                  <td>
                    <v-icon color="green" v-if="item.passed === true">mdi-check</v-icon>
                    <v-icon color="red" v-else-if="item.passed === false">mdi-close-outline</v-icon>
                    <v-icon color="yellow darken-3" v-else>mdi-timer-sand</v-icon>
                  </td>
                  <td>
                    <v-icon color="green" v-if="item.passedHigherRank === true">mdi-check</v-icon>
                    <v-icon color="red" v-else-if="item.passedHigherRank === false">mdi-close-outline</v-icon>
                    <v-icon color="yellow darken-3" v-else>mdi-timer-sand</v-icon>
                  </td>
                  <td>
                    <ExamSetPointsForm
                      :result-id="item.id"
                      :value="{ points: item.points, wasResit: item.wasResit }"
                      :max-value="item.examVersion.maxPoints"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { ListResultsForCourseExamDocument } from '@/generated/operations';
import ExamSetPointsForm from '@/pages/congressDate/widget/ExamSetPointsForm.vue';

export default {
  name: 'ExamRateModal',
  components: { ExamSetPointsForm },
  props: {
    examId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: '800px',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
  },
  apollo: {
    items: {
      query: ListResultsForCourseExamDocument,
      variables() {
        return {
          examId: this.examId,
          courseId: this.courseId,
        };
      },
      skip() {
        return !this.dialog;
      },
      update: (data) => data.list,
    },
  },
};
</script>
