










import CurrentUserExams from '@/pages/CurrentUserExams.vue';

export default {
  name: 'HomePage',
  components: { CurrentUserExams },
  methods: {
    test2(): any {
      return null;
    },
  },
  data() {
    return {
      test: true,
    };
  },
};
