<template>
  <v-container>
    <v-row v-if="user">
      <v-card class="mx-auto" max-width="800">
        <v-system-bar color="pink darken-2" dark>Federacja Kawalerii Ochotniczej</v-system-bar>
        <v-card-text>
          <div>Imię i nazwisko</div>
          <p class="text-h5 text--primary">{{ user.firstName }} {{ user.lastName | upper }}</p>
          <div>Itendyfikator</div>
          <p class="text--primary">{{ id }}</p>
          <div>Oddział</div>
          <p class="text--primary">{{ user.unit.ngoName || 'brak informacji' }}</p>
          <div>Reprezentuje barwy</div>
          <p class="text--primary">{{ user.unit.unitName || 'brak informacji' }}</p>
          <div>Stopień</div>
          <p class="text--primary">{{ user.rank.name }} kaw. och.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <p>
            Niniejszym zaświadcza się iż wyżej wymieniony kawalerzysta jest członkiem organizacji zrzeszonej w Federacji
            Kawalerii Ochotniczej
          </p>
          <p>Stan na {{ dateTime }}</p>
        </v-card-text>
      </v-card>
    </v-row>
    <v-skeleton-loader v-else type="table" />
  </v-container>
</template>

<script>
import USER_QUERY from '@/graphql/user/query/UserForIdCardQuery.gql';

export default {
  name: 'IdCardPage',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  filters: {
    upper: (value) => value.toUpperCase(),
  },
  computed: {
    dateTime: () => new Date().toISOString(),
  },

  methods: {},
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          userId: this.id,
        };
      },
      update: (data) => data.user,
    },
  },
};
</script>
