
















import { RankFormListDocument } from '@/generated/operations';

export default {
  name: 'RankSelectField',
  props: {
    activeOnly: {
      type: Boolean,
      default: () => false,
    },
    value: String,
    label: String,
    congressDateId: { type: String, required: false },
    rules: { type: Array },
    formValues: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    entitiesComputed() {
      if (!this.entities) {
        return [];
      }

      return this.entities;
    },
    internalValue: {
      get() {
        return String(this.value);
      },
      set(newValue) {
        this.$emit('input', String(newValue));
      },
    },
  },
  apollo: {
    entities: {
      query: RankFormListDocument,
      update(data) {
        return data.list.items;
      },
    },
  },
};
