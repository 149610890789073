<template>
  <div>
    <slot v-bind:currentUser="currentUser"></slot>
  </div>
</template>
<script>
import CURRENT_USER from '@/graphql/security/query/CurrentUserQuery.gql';

export default {
  name: 'AclScope',
  apollo: {
    currentUser: {
      query: CURRENT_USER,
      update(data) {
        this.$emit('input', data.me);
        return data.me;
      },
    },
  },
};
</script>
