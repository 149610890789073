<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <GraphqlServerSideDataGrid
          title="Kursy"
          :headers="headers"
          :query="query"
          :update="(data) => data.list.items"
          :addEnabled="false"
          @editItem="editItem"
          @displayItem="displayItem"
          :sort-by="['beginDate']"
        >
          <template v-slot:[`title-buttons-left`]>
            <AclScope v-slot="{ currentUser }">
              <AddCongressDateForm v-if="currentUser.acl_create_congress_date" />
            </AclScope>
          </template>
          <template v-slot:[`item.beginDate`]="{ item }">
            {{ item.beginDate | date }}
          </template>
          <template v-slot:[`item.number`]="{ item }">
            {{ item.number ? item.number : '-' }}
          </template>
          <template v-slot:[`item.courses`]="{ item }">
            <CongressCoursesListChip :congressCourses="item.congressCourses" />
          </template>
        </GraphqlServerSideDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QUERY from '@/graphql/congressDate/query/ListCongressDatesQuery.gql';
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import CongressCoursesListChip from '@/pages/congressDate/CongressCoursesListChip.vue';
import AclScope from '@/components/Common/Security/AclScope.vue';
import AddCongressDateForm from '@/pages/congressDate/form/AddCongressDateForm.vue';

export default {
  name: 'CongressDateListPage',
  components: { AddCongressDateForm, AclScope, CongressCoursesListChip, GraphqlServerSideDataGrid },
  data() {
    return {
      query: QUERY,
      headers: [
        // { text: 'ID', sortable: true, value: 'id' },
        { text: 'Numer zjazdu', sortable: true, value: 'number' },
        { text: 'Miejsce', sortable: true, value: 'place' },
        { text: 'Data', sortable: true, value: 'beginDate' },
        { text: 'Kursy', sortable: true, value: 'courses' },
        { text: 'Akcje', sortable: true, value: 'action' },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: 'users/edit', params: { id: item.id } });
    },
    displayItem(item) {
      this.$router.push({ name: 'congress-dates/display', params: { id: item.id } });
    },
  },
};
</script>
